// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = (props: PageProps) => (
  <Layout>
    <SEO title="Page two" />
    <div className="mx-auto max-w-7xl py-12 sm:px-6 lg:px-8">
      <div className="columns-1">
        <h1 className="text-4xl tracking-tight font-extrabold text-gray-200 sm:text-5xl md:text-6xl hues">Products</h1>
        <p className="mt-5 text-white">Stay tuned - Our product lineup is coming soon.</p>
    </div>
    <div className="columns-1 md:columns-3">
      <div><p className="mt-5 h-1/2 text-white bg-gray-800 align-center">Flo : User task flows simplified</p></div>
      <div><p className="mt-5 h-1/2 text-white bg-gray-800 align-center">Bones : Next Gen wireframing kit </p></div>
      <div><p className="mt-5 h-1/2 text-white bg-gray-800 align-center">Valentina : AI Design Assistant</p></div>
    </div>

    </div>
    
  </Layout>
)

export default SecondPage
